<template>
    <v-container class="page-container" fill-height fluid grid-list-xl
    >
        <v-layout wrap id="configuration">
          <h1 class="page-title text-xs-left mb-3 w-100">Issues</h1>
          <div class="flex-display flex-row flex-justify-between mb-3 w-100">
              <input class="search-container" v-model="searchIssue" placeholder="Search for an issue area…">
              <button class="btn add-new" @click="showIssueModal('add', null)"><i class="v-icon mdi mdi-plus"></i> New Issue</button>
          </div>
          <div class="item-container"
               v-for="issue in filterIssues"
               :key="issue.id"
          >
              <p class="item-text">{{issue.name}}</p>
              <div class="item-actions">
                    <button class="btn-edit" @click="showIssueModal('edit', issue)">Edit</button>
                    <button class="btn-delete ml-2" @click="deleteConfirmation(issue.id)">Delete</button>
              </div>
          </div>

          <!-- Add Issue -->
            <add-issue v-if="addIssueDialog"
                       :visible="addIssueDialog"
                       :action-type="action"
                       :value="issue"
                       v-on:addIssue="getIssues"
                       v-on:hide="hideIssueModal"
            >

            </add-issue>
          <!-- delete dialog -->
          <confirmation v-if="deleteDialog" :visible="deleteDialog">
                <template slot="title">
                    Are you sure?
                </template>
                <template slot="desc">
                    This Issue will be deleted. This action cannot be undone.
                </template>
                <template slot="actions">
                    <a class="cancel-model" @click="hideConfirmation">Cancel</a>
                    <button class="btn modal-action-button confirmation-delete" @click="DeleteIssue">Delete</button>
                </template>
          </confirmation>
        </v-layout>

    </v-container>
</template>

<script>
    import Confirmation from "../components/common/Confirmation";
    import AddIssue from "../components/configuration/addIssue";
    export default {
        components: {AddIssue, Confirmation},
        data: () => ({
             issues: [],
             issue: null,
             searchIssue: '',
             deleteDialog: false,
             addIssueDialog: false,
             action : 'add', // it will differentiate that we are opening the issue modal for add or update
             activeIssue: 0
        }),
        computed: {
            filterIssues() {
                let issues = this.issues;
                if (this.searchIssue) {
                    issues = issues.filter((p) => {
                        return p.name.toLowerCase().indexOf(this.searchIssue.toLowerCase()) !== -1;
                    });
                }
                return issues;
            },
        },
        created(){
           this.getIssues();
        },
        methods:{
            getIssues(){
                let self = this;
                let loader = this.$loading.show();
                self.hideIssueModal();
                this.$http.get(this.url+'issues', {
                }).then(function (response) {
                    loader.hide();
                    self.issues = response.data.data;
                }).catch(function (error) {
                    loader.hide();
                    self.$toastr('error', error.response.data.message, 'Error');
                })
            },
            deleteConfirmation(id) {
                this.deleteDialog = true;
                this.activeIssue =  id;
            },
            hideConfirmation () {
                this.deleteDialog = false;
                this.activeIssue = 0 ;
            },
            DeleteIssue(){
                let self = this;
                self.deleteDialog = false;
                let loader = this.$loading.show();
                this.$http.delete(this.url+'delete_issue', {
                    data: {
                        id: this.activeIssue
                    }
                }).then(function (response) {
                    loader.hide();
                    self.$toastr('success', response.data.message);
                    self.getIssues();
                }).catch(function (error) {
                    loader.hide();
                    self.$toastr('error',error.response.data.message);
                })
            },
            showIssueModal(action, value){
                this.addIssueDialog = true;
                this.action = action;
                this.issue  = value;
            },
            hideIssueModal(){
                this.addIssueDialog  = false;
            }

        }
    }
</script>
